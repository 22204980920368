@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #000000;
  width: 100%;
  overflow-x: hidden;
}
html{
  overflow-x: hidden;

}
h1{
  @apply font-sofia leading-[1em] font-black text-[60px] sm:text-[100px] md:text-[108px] lg:text-[120px] xl:text-[135px];
}
h2{
  @apply text-left text-[24px] sm:text-[36px] lg:text-[48px] text-[white] font-sofia font-black pb-[1em] uppercase;
}
p{
  @apply text-[16px]  lg:text-[20px] text-left text-white;
}
.containerr{
  @apply px-[5vw] sm:px-[10vw] py-[50px];
}
.flexCol{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100000;
  overflow: hidden;

}
.underline-animation{
  position: relative; 
}

.underline-animation::before {
  content: ""; 
  position: absolute;
  width: 0; 
  height: 2px; 
  background-color: currentColor; 
  bottom: 0; 
  left: 0; 
  transition: width 0.3s; 
}

.underline-animation:hover::before {
  width: 100%; 
}


#burger-menu {
  cursor: pointer;
  height: 29px;
  width: 40px;
  margin: 0 5vw;
  overflow: visible;
  position: relative;
  z-index: 2;
}

#burger-menu span,
#burger-menu span:before,
#burger-menu span:after {
  display: block;
  height: 5px;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in-out;
  background: #FF1E00;

}



#burger-menu span:before,
#burger-menu span:after {
  content: "";
}

#burger-menu span {
  right: 0px;
  top: 13px;
  width: 40px;
}

#burger-menu span:before {
  left: 0px;
  top: -12px;
  width: 29px;
}

#burger-menu span:after {
  left: 0px;
  top: 12px;
  width: 33px;
}

#burger-menu.close span {
  transform: rotate(-45deg);
  top: 13px;
  width: 40px;
}

#burger-menu.close span:before {
  top: 0px;
  transform: rotate(90deg);
  width: 40px;
}

#burger-menu.close span:after {
  top: 0px;
  left: 0;
  transform: rotate(90deg);
  opacity: 0;
  width: 0;
}

#menu {
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

#menu.overlay {
  visibility: visible;
  opacity: 0.95;

  background: #1E1E1E;
}

#menu li {
  list-style: none;
  display: block;
  text-decoration: none;
  text-align: left;

}
.btn{
  @apply border-[1px] border-[#FF1E00] text-[#FF1E00]  rounded-[50px] font-[300] px-[1em];
  transition: 0.2s ease-in;
}
.activeNavBtn{
    @apply border-[1px] border-[#FF1E00] bg-[#FF1E00] text-[white]  rounded-[50px] font-[300] px-[1em];

}
.btn:hover{
  @apply border-[1px] border-[#FF1E00] bg-[#FF1E00] text-[white]  rounded-[50px] font-[300] px-[1em];

}
.btn-solid{
  @apply text-[22px] sm:text-[24px] lg:text-[30px]  border-[4px] border-[#FF1E00] bg-[#FF1E00] text-[white]  rounded-[50px] font-[500] px-[0.9em] py-[0.2em];
  transition: 0.2s ease-out;
}
.btn-solid:hover{
  @apply bg-white text-[#FF1E00]
}
.gradient-overlay{
  /* Rectangle 2 */

position: absolute;
width: 100%;
height: 100%;

background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.85) 20.29%, rgba(0, 0, 0, 0.3) 100%);

}
.carsdata p{
  @apply text-[12px] sm:text-[14px] md:text-[18px] lg:text-[20px];
}
.contactForm input, .contactForm textarea, .contactForm select{
  @apply bg-black border-[white] border-[1.5px];
}

.contactForm input:focus, textarea:focus, .textarea select:focus{
  outline: 2px solid #FF1E00;
  border: 0px;
}

/* FAQS */


.contentcontainer {
  @apply px-[4vw] py-[1em] sm:py-[1.5em] mt-[1rem] w-full rounded-[15px] sm:rounded-[20px];
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  
}
.question {
  @apply  text-[16px] sm:text-[20px] md:text-[24px];
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 4rem;
}
.question::after {
  content: "\002B";
  font-size: 3rem;
  font-weight: 300;
  position: absolute;
  right: 20px;
  transition: 0.2s;
}
.question.active::after {
  transform: rotate(45deg);
}
.answercont {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.answer {
  @apply text-left text-[14px] sm:text-[16px] md:text-[20px];
  line-height: 1.5rem;
  font-weight: 300;
  margin-top: 20px;

}
.question.active + .answercont {
  max-height: 1000px; /* Large enough value to accommodate the answer content */
}

.textarea{
  background-color: #fff;
}

.add-button{
  @apply text-[18px] bg-[#0095ff] px-[1em] py-[0.3em] text-[white] rounded-[8px] mt-[20px];
}
.delete-button{
  @apply text-[18px] bg-[red] px-[1em] py-[0.3em] text-[white] rounded-[8px] mt-[20px];
}
.adminDashboardForm{
  border: 1px solid rgba(0,0,0,0.45);
  background-color: #ececec;
  box-shadow: 25px 0px 20px -20px rgba(0,0,0,0.45),
  -25px 0px 20px -20px rgba(0,0,0,0.45);  
  border-radius: 30px;
}
.adminDashboardForm textarea, .adminDashboardForm input {
  margin-top: 5px;
  width: 100%;
  padding: 10px 15px 10px 15px;
  border: 1px solid #949494;
  border-radius: 10px;
  margin-left: 0;
}
.adminDashboardForm img{
  border: 1px solid #949494;
  border-radius: 10px;
}

.arrayElement{
  background: #ffffff;
  padding: 30px;
  border: 1px solid #949494;
  border-radius: 10px;
  margin-top: 10px;
}


.activeNav{
  color: #FF1E00;
}
.sponsordiv{
  transition: 0.2s ease-out;
  background-color: rgba(255, 255, 255, 1);
}
.sponsordiv:hover{
  background-color: rgba(255, 255, 255, 0.4);

}